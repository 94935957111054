/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import "jit-refresh.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "custom/_fonts.scss";
@import "custom/_general.scss";

@import "components/_buttons.scss";
@import "components/_breadcrumb.scss";

@import "custom/structure/_topnav.scss";
@import "custom/structure/_footer.scss";

@import "custom/pages/_helper.scss";
@import "custom/pages/_hero.scss";
@import "custom/pages/_countdown.scss";
@import "custom/pages/_portfolio.scss";
@import "custom/pages/_contact.scss";


@import "custom/plugins/_swiper-slider.scss";
@import "custom/plugins/_testi.scss";
@import "custom/plugins/_choices.scss";
@import "custom/plugins/_datepicker.scss";

