/* // _footer.scss */

/*********************************/
/*         Footer                */
/*===============================*/
.footer {
    .foot-subscribe {
        input{
            &::placeholder{
                @apply text-gray-200;
            }
        }
    } 
}